<template>
  <quill-editor
    ref="quillEditorRef"
    theme="bubble"
    :toolbar="editorConfig?.toolbar"
    :content-type="editorConfig?.contentType"
    @contextmenu.prevent="showToolbarBubble"
  >
    <template
      v-for="(_, name) in slots"
      #[name]
      :key="'quill-editor-slot' + name"
    >
      <slot :name="name" />
    </template>
  </quill-editor>
</template>

<script>
import { reactive, ref } from 'vue';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.bubble.css';

export default {
    name: 'QuillTextEditor',

    components: {
        QuillEditor
    },

    setup (props, { slots }) {
        const editorConfig = ref({
            toolbar: [
                ['bold', 'italic'],
                ['image'],
                [
                    { list: 'ordered' },
                    { list: 'bullet' }
                ],
                [
                    { align: '' },
                    { align: 'center' },
                    { align: 'right' },
                    { align: 'justify' }
                ]
            ],
            contentType: 'html'
        });

        const quillEditorRef = ref(null);
        const showToolbarBubble = () => {
            const quillInstance = quillEditorRef.value.getQuill();
            quillInstance.theme.tooltip.edit();
            quillInstance.theme.tooltip.show();
        };

        return {
            slots,
            editorConfig,
            quillEditorRef,
            showToolbarBubble
        };
    }
};
</script>
